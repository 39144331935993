import React, { useCallback, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles
} from '@material-ui/core/styles';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { ListItemSecondaryAction } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DevicesIcon from '@material-ui/icons/Devices';
import StorageIcon from '@material-ui/icons/Storage';
import styled from '@emotion/styled';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { useAuth } from 'reducers/auth';
import { firebaseApp } from 'schema';
import { NavigationPath } from '@shared/schema';
import { useTranslation } from 'react-i18next';

interface DrawerData {
  title: string;
  icon: JSX.Element;
  path: string;
}

const drawerItems: DrawerData[] = [
  {
    title: 'Devices', // t('Devices')
    icon: <DevicesIcon />,
    path: '/devices',
  },
  {
    title: 'Data',  // t('Data')
    icon: <StorageIcon />,
    path: '/data',
  },
];

const drawerWidth = 240;
const logoWidth = 120;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexGrow: 1,
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    title: {
      flexGrow: 1,
    },
  })
);

const Container = styled.div`
  min-height: 100v;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 8px;
`;

const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const StyledLogo = styled.img`
  width: ${logoWidth}px;
  margin-left: ${(drawerWidth - logoWidth) / 2}px;
  padding-top: 8px;
`;

const UsernameText = styled.li`
  white-space: nowrap;
  font-size: 1rem;
  margin: 8px 16px;
`;

export default function ResponsiveDrawer({ children, }) {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen,] = useState(false);
  const [anchorEl, setAnchorEl,] = useState<null | HTMLElement>(null);
  const router = useRouter();
  const { user, } = useAuth();
  const { t, } = useTranslation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenu = (event: React.MouseEvent<HTMLInputElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogOutClickHandler = useCallback(async () => {
    try {
      await firebaseApp().auth().signOut();
      router.push(NavigationPath.LOGIN);
    } catch (error) {
      console.error(error);
    }
  }, [router,]);

  const drawer =
    <Container>
      <div style={{ height: '64px', display: 'flex', alignItems: 'center', }}>
        <StyledLogo src={'/images/logo.jpg'} alt={'Vigilan logo'} />
      </div>
      <Divider />
      <DrawerContent>
        <List>
          {drawerItems.map(({ title, icon, path, }) => {
            const compare = router.pathname.toLocaleLowerCase() as string;
            const selected =
              compare === '/' ? path === '/devices' : compare.startsWith(path);
            return (
              <Link key={title} as={path} href={path}>
                <ListItem button key={title} selected={selected}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={t(title)} />
                  {selected &&
                    <ListItemSecondaryAction>
                      <ChevronRightIcon />
                    </ListItemSecondaryAction>
                  }
                </ListItem>
              </Link>
            );
          })}
        </List>
      </DrawerContent>
    </Container>
    ;
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title}>
            {t('Dashboard')}
          </Typography>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={!!anchorEl}
              onClose={handleClose}
            >
              <UsernameText>{user?.displayName}</UsernameText>
              <Divider />
              <MenuItem onClick={onLogOutClickHandler}>
                <Typography variant="inherit">{t('Logout')}</Typography>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}
