import React from 'react';
import styled from '@emotion/styled';

const AppContainer = styled.div`
  display:flex;
  flex-direction: column;
  height: 100vh;
`;

interface AppProps {
  showBackButton?: boolean;
  appStyle?: React.CSSProperties;
}

const App = ({ children, appStyle = {}, }: React.PropsWithChildren<AppProps>) =>
  <AppContainer style={appStyle}>
    {children}
  </AppContainer>;

export default App;